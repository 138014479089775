import { Button, Card, CardContent, TextField } from "@mui/material";
import AppLayout from "../../../components/layout/AppLayout";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useSelector } from "react-redux";

import CustomInputLabel from "../../../components/forms/CustomInputLabel";
import instance from "../../../auth/utils/useJwt";
import { useEffect, useState } from "react";
import { DATE_FORMAT } from "../../../configs/basic";
import { useNavigate } from "react-router-dom";
import BContainer from "../../../components/BContainer";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { MuiChipsInput } from 'mui-chips-input'
import { SuperAdminAction } from "../../../components/PermissionsWrappers/AdminAction";

export default function AdminAppSettings() {
    const [loading, setLoading] = useState(false)
    const user = useSelector(state => state.user)
    const [message, setMessage] = useState({
        type: '',
        text: ''
    })
    

    const [settings, setSettings] = useState({
        name: "Romac eHR",
        brand: "Romac eHR",
        dateFormat: DATE_FORMAT,
        referral_receiver_emails: [],
        xero_receiver_emails: []
    })
    const updateData = () => {
        setLoading(true)
        instance.patch('/settings', settings).then((res) => {
            console.log(res.data)
            setLoading(false)
            setMessage({
                type: 'success',
                text: 'Settings updated successfully'
            })
        }
        ).catch((err) => {
            console.log(err)
            setMessage({
                type: 'error',
                text: 'Error updating settings'
            })
            setLoading(false)
        })
    }

    // Clear message after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setMessage({
                type: '',
                text: ''
            })
        }, 5000);
        return () => clearTimeout(timer);
    }, [message]);

    useEffect(() => {
        var st = localStorage.getItem("romac_settings")
        console.log(st)
        // Parse JSON string to JSON Object
        if (st){
            st = JSON.parse(st)
            if (!st.referral_receiver_emails){
                st.referral_receiver_emails = []
            }
            setSettings(st)
        }
    }, [])

    const onChange = (e) => {
        setSettings({ ...settings, [e.target.name]: e.target.value })
    }


    const breadcrumbs_v = [
        { url: "#", text: "Admin" },
        { text: "Settings" },
    ]

    return (
        <AppLayout title="Settings" px={0} apppadding={"0px"}>
            <BContainer>
                <BreadCrumbs data={breadcrumbs_v} />

            </BContainer>
            <Box sx={{ px: 3, py: 1 }}>
                <Card>
                    {/* <CardHeader title="Application Settings" sx={{ borderBottom: '1px solid #ddd' }} /> */}
                    <CardContent sx={{ width: '500px', margin: '0 auto' }}>
                        <Typography variant="body1">
                            Application Settings, modify name, logo, etc.
                        </Typography>


                        <Box sx={{ pt: 2 }}>
                            <CustomInputLabel>Application Name</CustomInputLabel>
                            <TextField name="name" placeholder="Application Name" fullWidth variant="outlined"
                                value={settings.name} onChange={onChange} />
                        </Box>

                        <Box sx={{ pt: 2, pb: 1 }}>
                            <CustomInputLabel>Brand Name</CustomInputLabel>
                            <TextField name="brand" placeholder="Brand Name" fullWidth variant="outlined"
                                value={settings.brand} onChange={onChange} />
                        </Box>

                        <Box sx={{ py: 1 }}>
                            <CustomInputLabel>Timezone</CustomInputLabel>
                            <TextField name="dateFormat" placeholder="Date Format" fullWidth variant="outlined"
                                value={settings.dateFormat} onChange={onChange} />
                        </Box>
                        {/* <Box sx={{ py: 1 }}>
                            <CustomInputLabel>Referral Email Receivers</CustomInputLabel>
                            <MuiChipsInput name="referral_receiver_emails" placeholder="Referral Email Receivers (Press Enter to add)" fullWidth variant="outlined"
                                value={settings.referral_receiver_emails} onChange={(chips) => {
                                    setSettings({ ...settings, referral_receiver_emails: chips })
                                }} />
                        </Box>
                        <Box sx={{ py: 1 }}>
                            <CustomInputLabel>XERO Email Receivers</CustomInputLabel>
                            <MuiChipsInput name="xero_receiver_emails" placeholder="XERO Email Receivers (Press Enter to add)"
                             fullWidth variant="outlined"
                                value={settings.xero_receiver_emails} onChange={(chips) => {
                                    setSettings({ ...settings, xero_receiver_emails: chips })
                                }} />
                        </Box> */}
                    </CardContent>
                    <SuperAdminAction>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #ddd', py: 2, px: 2 }}>
                        <Typography variant="body1" sx={{ pt: 1 }} color={message.type === 'success' ? 'success.main' : 'error.main'}>
                            {message.text ? message.text : ''}
                        </Typography>
                        <Button
                            onClick={updateData}
                            disabled={loading}
                            variant="contained" color="primary" sx={{ mr: 1 }}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </Box>
                    </SuperAdminAction>
                </Card>
            </Box>

        </AppLayout>
    )
}