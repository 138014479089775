import { Box, Card, CardContent, CardHeader, Table, TableBody, TableCell, TableRow } from "@mui/material";

export default function UserDetails({ data }) {
    const { phone, country, timezone } = data;

    return (
        <Box>
            <Card>
                <CardHeader title="User Details" sx={{ fontSize: '17px' }} />
                <CardContent>
                    <Box>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>{phone ? phone : 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Country</TableCell>
                                    <TableCell>{country ? country : 'N/A'}</TableCell>
                                </TableRow>
                                {/* <TableRow>
                                    <TableCell>Region</TableCell>
                                    <TableCell>{region ? region : 'N/A'}</TableCell>
                                </TableRow> */}
                                <TableRow>
                                    <TableCell>Timezone</TableCell>
                                    <TableCell>{timezone ? timezone : 'N/A'}</TableCell>
                                </TableRow>
                                {/* <TableRow>
                                    <TableCell>Xero Access</TableCell>
                                    <TableCell>{hasExpenseRole ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Donations</TableCell>
                                    <TableCell>{hasDonationRole ? 'Yes' : 'No'}</TableCell>
                                </TableRow> */}
                            </TableBody>
                        </Table>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}
