import { Alert, Checkbox, CircularProgress } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { useEffect } from "react";

import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from "react-redux";
import { fetchHospitals } from "../../../../../redux/medical/hospital";
import { fetchClinicians } from "../../../../../redux/medical/clinicians";
import { fetchClinicianTypes } from "../../../../../redux/medical/clinician_types";
import { fetchSponsors } from "../../../../../redux/general/sponsors";
import { fetchRegions } from "../../../../../redux/region";
import { fetchDistricts } from "../../../../../redux/districts";
import { setCaseValue } from "../../../../../redux/case/case";
import Clinicians from "../components/Clinicians";
import HospitalSelection from "../components/Hospital";

import DatePicker from "../../../../../components/forms/DatePicker";
import CustomInputLabel from "../../../../../components/forms/CustomInputLabel";
import OriginalEstimate from "../components/OriginalEstimate";
import AcceptanceTable from "./AcceptanceTable";


export default function Acceptance() {

    const dispatch = useDispatch()

    const patient_case = useSelector(state => state.patient_case)
    const { loading } = patient_case
    const case_data = patient_case.data
    const hospitals = useSelector(state => state.hospitals).data
    const clinicians = useSelector(state => state.clinicians).data
    const clinician_types = useSelector(state => state.clinician_types).data
    const sponsors = useSelector(state => state.sponsors).data
    const regions = useSelector(state => state.regions).data
    const districts = useSelector(state => state.districts).data


    useEffect(() => {
        if (hospitals.length === 0) {
            dispatch(fetchHospitals())
        }
        if (clinicians.length === 0) {
            dispatch(fetchClinicians())
        }
        if (clinician_types.length === 0) {
            dispatch(fetchClinicianTypes())
        }
        if (sponsors.length === 0) {
            dispatch(fetchSponsors())
        }
        if (regions.length === 0) {
            dispatch(fetchRegions())
        }
        if (districts.length === 0) {
            dispatch(fetchDistricts())
        }
    }, [])


    const onInputChange = (e) => {
        const { name, value, checked } = e.target
        if (['md_approved', 'rm_approved', 'board_approved', 'insurance_office_contacted'].includes(name)) {
            dispatch(setCaseValue({
                name: name, value: checked
            }))
        } else {
            dispatch(setCaseValue({
                name: name, value: value
            }))
        }
    }

    if (loading) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', py: 5 }}>
            <CircularProgress />
        </Box>
    }

    return (
        <Box>
            <Box sx={{ my: 2 }}>
                <Grid container spacing={2}>
                    <Grid item md={6}>

                        {/* <AcceptanceTable /> */}

                        <CustomInputLabel>Acceptance</CustomInputLabel>
                        {/* <Box sx={{ display: 'flex', gap: '5px' }}>
                            <Checkbox />
                            <InputLabel sx={{ fontSize: '13px', mt: 1.5, minWidth: '120px' }}>
                                Meets Guidelines, subject to more detailed assessment
                            </InputLabel>
                        </Box> */}
                        <Box sx={{ display: 'flex', gap: '5px' }}>
                            <Checkbox onChange={onInputChange} checked={case_data.md_approved} name="md_approved" />
                            <InputLabel sx={{ fontSize: '13px', mt: 1.5, minWidth: '120px' }}>Medical Director</InputLabel>
                            <TextField onChange={onInputChange} size="small" fullWidth value={case_data.md_note} name="md_note" />
                        </Box>
                        <Box sx={{ my: 1 }}>
                            <Alert sx={{ fontSize: '12px' }} severity="info">
                        Case meets Medical Acceptance Guidelines, subject to more detailed assessment
                        </Alert>
                        </Box>
                        <CustomInputLabel>Approval</CustomInputLabel>
                        <Box sx={{ display: 'flex', gap: '5px', my: 1, mt: 2 }}>
                            <Checkbox onChange={onInputChange} checked={case_data.rm_approved} name="rm_approved" />
                            <InputLabel sx={{ fontSize: '13px', mt: 1.5, minWidth: '120px' }}>Regional Chair</InputLabel>
                            <TextField size="small" onChange={onInputChange} fullWidth value={case_data.rm_note} name="rm_note" />
                        </Box>
                        <Box sx={{ display: 'flex', gap: '5px', my: 1 }}>
                            <Checkbox onChange={onInputChange} checked={case_data.board_approved} name="board_approved" />
                            <InputLabel sx={{ fontSize: '13px', mt: 1.5, minWidth: '120px' }}>Board Members</InputLabel>
                            <TextField size="small" onChange={onInputChange} fullWidth value={case_data.board_note} name="board_note" />
                        </Box>


                        <Box sx={{ my: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <CustomInputLabel>Region</CustomInputLabel>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={regions.map(({ id, name }) => ({ label: name, value: id }))}
                                        fullWidth
                                        value={case_data.region}
                                        onChange={(e, val) => dispatch(setCaseValue({
                                            name: "region", value: val
                                        }))}
                                        size="small"
                                        renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <CustomInputLabel>District</CustomInputLabel>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={districts.map(({ id, name }) => ({ label: name, value: id }))}
                                        fullWidth
                                        multiple
                                        value={case_data.district}
                                        onChange={(e, val) => dispatch(setCaseValue({
                                            name: "district", value: val
                                        }))}
                                        size="small"
                                        renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                                    />

                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ my: 2 }}>
                            <CustomInputLabel>Insurance Sponsoring Rotary Club</CustomInputLabel>
                        </Box>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item md={12}>
                                    <CustomInputLabel>Rotary Club</CustomInputLabel>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={sponsors.map(({ id, name }) => ({ label: name, value: id }))}
                                        fullWidth
                                        value={case_data.sponsor}
                                        onChange={(e, val) => dispatch(setCaseValue({
                                            name: "sponsor", value: val
                                        }))}
                                        size="small"
                                        renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ my: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Box sx={{ display: 'flex' }}>
                                        <Checkbox checked={case_data.insurance_office_contacted} onChange={onInputChange} name="insurance_office_contacted" />
                                        <InputLabel sx={{ fontSize: '13px', mt: 1.5 }}>Insurance Office Contacted</InputLabel>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Box>

                        <Box sx={{ my: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <CustomInputLabel>Date Notified {case_data.insurance_date_notified}</CustomInputLabel>
                                    <DatePicker name="insurance_date_notified" value={case_data.insurance_date_notified} onChange={(e) => dispatch(setCaseValue(e))} />
                                </Grid>
                                <Grid item md={6}>
                                    <CustomInputLabel>Who Notified</CustomInputLabel>
                                    <TextField size="small" name="insurance_who_notified" onChange={onInputChange} value={case_data.insurance_who_notified} />
                                </Grid>


                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item md={6}>
                        <HospitalSelection />
                        <Clinicians />

                    </Grid>
                </Grid>
                <Box>
                    <OriginalEstimate />
                </Box>
            </Box>


        </Box>
    )
}