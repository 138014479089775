import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useParams } from 'react-router-dom';
import { Box, TextField, IconButton, Typography, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/system';
import instance from '../../../../auth/utils/useJwt';
import { useSelector } from 'react-redux';

import EditIcon from '@mui/icons-material/Edit';
import DeleteMemo from './Dialogs/DeleteMemo';
import CustomInputLabel from '../../../../components/forms/CustomInputLabel';
import GeneralDialog from '../../../../components/Dialogs/CustomDialog';
import { MUIButton } from '../../../../components/forms/common/MUIButton';
import CaseAction from '../../../../components/PermissionsWrappers/CaseAction';

import TZDateView from '../../../../components/General/TZDateView';

const CTableCell = styled(TableCell)(({ theme }) => ({
    fontSize: '13px', color: "#333"
}))


export default function Memos(props) {
    const [rows, setRows] = useState([])
    const [memosData, setMemosData] = useState([])
    const [selectedRow, setSelectedRow] = useState(null)
    const { is_readonly } = useSelector(state => state.patient_case)
    const { patient_id, case_id } = useParams()
    const [apiReq, setApiReq] = useState({ loading: true, error: null })
    const onAddMemo = () => {
        // setRows([...rows, {
        //     datetime: "9 0 click",
        //     who: 'faizanamin192@gmail.com',
        //     content: val
        // }])
        fetchMemos()
    }

    const fetchMemos = () => {
        setApiReq({ loading: true, error: null })
        instance.get(`/case-memos?patient_id=${props.patient_id}&case_id=${case_id}`).then(res => {
            console.log(res)
            setApiReq({ loading: false, error: null })
            setMemosData(res.data)
        }).catch(err => {
            setApiReq({ loading: false, error: null })
            console.log(err)
        })
    }

    useEffect(() => {
        fetchMemos()
    }, [])



    return (
        <Box>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                    <TableHead sx={{ backgroundColor: '#f6f6f7' }}>
                        <TableRow>
                            <TableCell>Date / Time</TableCell>
                            <TableCell align="right">Who</TableCell>
                            <TableCell align="right">Memo Content</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            apiReq.loading &&
                            <TableRow>
                                <TableCell colSpan={4} sx={{ backgroundColor: "#F2F9FE", textAlign: 'center', py: 1, color: 'rgb(84, 176, 242)' }}>
                                    Loading...
                                </TableCell>
                            </TableRow>
                        }
                        {
                            memosData.length === 0 && !apiReq.loading &&
                            <TableRow>
                                <TableCell colSpan={4} sx={{ backgroundColor: "#F2F9FE", textAlign: 'center', py: 1, color: 'rgb(84, 176, 242)' }}>There are no memos.</TableCell>
                            </TableRow>
                        }
                        {memosData.map((row, index) => (
                            <TableRow
                                key={`memo-${index}`}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <CTableCell sx={{ minWidth: '170px' }}>
                                    <TZDateView date={row.timestamp}
                                    outputFormat="EEE, MMM dd yyyy hh:mm a" 
                                />
                                    </CTableCell>
                                <CTableCell align="right" sx={{ py: 1.1, minWidth: '180px' }}>{row.editor}</CTableCell>
                                <CTableCell align="right" sx={{ width: '100%' }}>{row.text}</CTableCell>

                                <CTableCell align="right" sx={{ maxWidth: '130px', minWidth: '60px' }}>
                                    <IconButton size='small' onClick={() => setSelectedRow(row)}>
                                        <EditIcon color='primary' sx={{ fontSize: '20px' }} />
                                    </IconButton>
                                    {/* <IconButton size='small'>
                                        <DeleteForeverIcon color='error' sx={{ fontSize: '20px' }} />
                                    </IconButton> */}
                                    <DeleteMemo item={row} fetchMemos={fetchMemos} />
                                </CTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {!is_readonly &&
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <AddMemo onAddMemo={onAddMemo} patient_id={props.patient_id} case_id={case_id} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
                </Box>
            }
        </Box>
    );
}


function AddMemo(props) {
    const [open, setOpen] = React.useState(false);
    const [inputData, setInputData] = React.useState('')
    const [apiReq, setApiReq] = useState({ loading: false, error: null })
    const user = useSelector(state => state.user)
    const handleClickOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (props.selectedRow) {
            setInputData(props.selectedRow.text)
            setOpen(true)
        }
    }, [props.selectedRow])

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        // props.onAddMemo(inputData)
        setApiReq({ loading: true, error: null })
        const data = { text: inputData, editor: user.email, patient_id: props.patient_id, case_id: props.case_id }

        if (!props.selectedRow) {
            instance.post("/case-memos", data).then(res => {
                setApiReq({ loading: false, error: null })
                console.log(res)

                setOpen(false);

                setInputData("")
                props.onAddMemo()
            }).catch(err => {
                console.log("Error")
                setApiReq({ loading: false, error: "error saving memo" })
            })
        }
        else {
            data.id = props.selectedRow.id
            instance.patch("/case-memos", data).then(res => {
                setApiReq({ loading: false, error: null })
                console.log(res)

                props.setSelectedRow(null)
                setOpen(false);

                setInputData("")
                props.onAddMemo()
            }).catch(err => {
                console.log("Error")
                setApiReq({ loading: false, error: "error saving memo" })
            })
        }




    }

    return (
        <div>
            <CaseAction>
            <MUIButton variant='contained' size='small' sx={{ textTransform: 'capitalize', pt: 0.7 }} startIcon={<AddIcon />} onClick={handleClickOpen}>
                Add Memo
            </MUIButton>
            </CaseAction>
            <GeneralDialog
                open={open}
                handleClose={handleClose} title={props.selectedRow ? "Update Memo" : "Add Memo"}
                disabled={apiReq.loading}
                actions={
                    <Stack direction="row" spacing={2}>
                        <Button onClick={handleClose} disabled={apiReq.loading} variant="outlined">Cancel</Button>
                        <Button onClick={handleSave} disabled={apiReq.loading} variant="contained">
                            {
                                apiReq.loading ? `Saving` : `Save`
                            }
                        </Button>
                    </Stack>
                }>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CustomInputLabel>Please enter your memo here:</CustomInputLabel>
                    <TextField rows={4} multiline value={inputData} onChange={(e) => setInputData(e.target.value)} />
                </Box>

                {
                    apiReq.error &&
                    <Typography sx={{ mt: 1, fontSize: '13px' }} color="error" variant="body1">{apiReq.error}</Typography>
                }
            </GeneralDialog>
        </div>
    );
}
