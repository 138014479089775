import React, { useEffect } from "react";
import AppLayout from "../../../components/layout/AppLayout";
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton';

import AddUser from "./AddUser";
import LoopIcon from '@mui/icons-material/Loop';
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../../redux/users";
import Tooltip from '@mui/material/Tooltip';
import BContainer from "../../../components/BContainer";
import BreadCrumbs from "../../../components/BreadCrumbs";
import MuiDataGrid from "../../../components/tables/MUIDataGrid";
import { COLUMNS } from "./utilities";
import AdminAction from "../../../components/PermissionsWrappers/AdminAction";
import ExportUsers from "./components/ExportUsers";
import { Stack } from "@mui/material";

export default function AdminUsers() {
    const dispatch = useDispatch()
    const { data, loading } = useSelector(state => state.users)
    useEffect(() => {
        dispatch(fetchUsers())
    }, [dispatch])

    return (
        <AppLayout title="Admin - Users" px={0} apppadding={"0px"}>
            <BContainer>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <BreadCrumbs data={[
                        { url: "#", text: "Admin" },
                        { text: "Users" }
                    ]} />
                </Box>
                <Box sx={{ display: 'flex' }} >
                    <Tooltip title="Refresh users">
                        <IconButton size="small" onClick={() => dispatch(fetchUsers())}>
                            <LoopIcon />
                        </IconButton>
                    </Tooltip>

                    <Stack sx={{ pt: 0.4, px: 1 }} gap={2} direction={"row"}>
                        <ExportUsers />
                        <AdminAction>
                            <AddUser />
                        </AdminAction>

                    </Stack>
                </Box>

            </BContainer>
            <Box>
                <MuiDataGrid data={data} loading={loading} columns={COLUMNS} />

            </Box>

        </AppLayout>
    )
}