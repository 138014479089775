
import { Box, IconButton, Stack } from "@mui/material";
import DeleteForeverOutlined from "@mui/icons-material/DeleteForeverOutlined";
import { useDispatch, useSelector } from "react-redux";

import CustomInputLabel from "../../../../../components/forms/CustomInputLabel";
import { useEffect } from "react";
import { fetchRegions } from "../../../../../redux/region";
import AdminAction from "../../../../../components/PermissionsWrappers/AdminAction";


export default function SelectedPermissions({
    selectedPermissions,
    setSelectedPermissions
}) {

    const dispatch = useDispatch()
    const regions = useSelector(state => state.regions).data


    const handlePermissionDelete = (index) => {
        setSelectedPermissions([...selectedPermissions.slice(0, index), ...selectedPermissions.slice(index + 1)])
    }


    useEffect(() => {
        if (regions.length === 0) {
            dispatch(fetchRegions())
        }
    }, [dispatch, regions.length])

    console.log(selectedPermissions)
    return (
        <Box>
            {
                selectedPermissions.filter(permission => permission.category !== "Basic").map((permission, index) => (
                    <Stack gap={1} direction={"row"} key={`selected-${index}`} sx={{ mb: 2 }} justifyItems={"center"}>
                        <Box sx={{ minWidth: '200px', maxWidth: "300px" }}>
                            <CustomInputLabel>Permission</CustomInputLabel>
                            <Box sx={{ border: '1px solid #ddd', px: 2, py: 1, borderRadius: "4px" }}>
                                {permission.name}
                            </Box>
                            <Box sx={{
                                color: "#888", fontSize: "12px"
                            }}>
                                {permission.description}
                            </Box>
                        </Box>
                        {/* {
                            ["Case Viewer", "Case Manager"].includes(permission.name) && (
                                <Box sx={{ width: "150px" }}>
                                    <CustomInputLabel>Region</CustomInputLabel>
                                    <Select size="small" name="region" fullWidth onChange={(e) => onRegionChange(e, index)} value={permission.region || ""}>
                                        {
                                            regions.map(({ id, name }) => (
                                                <MenuItem key={`region-${id}`} value={id}>{name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                    <Box sx={{
                                        color: "#888", fontSize: "12px"
                                    }}>
                                        Select the region for this permission
                                    </Box>
                                </Box>
                            )
                        } */}
                        <Box>
                            <AdminAction>
                            <CustomInputLabel>&nbsp;</CustomInputLabel>
                            <IconButton onClick={() => handlePermissionDelete(index)}>
                                <DeleteForeverOutlined />
                            </IconButton>
                            </AdminAction>
                        </Box>
                    </Stack>
                ))
            }
        </Box>
    )
}