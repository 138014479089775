import AppLayout from "../../components/layout/AppLayout";
import { Grid, Box } from "@mui/material";
import UserCard from "./components/UserCard";
import RecentPatients from "./components/RecentPatients";
import BasicCards from "./components/BasicCards";
export default function Dashboard() {

    return (
        <AppLayout apppadding={"0px"}>
            <Grid container spacing={1}>
                <Grid item md={12}>
                    <Box sx={{ px: 3 }}>
                        <Grid container spacing={2} rowSpacing={2} sx={{ py: 3 }}>
                            <Grid item md={8}>
                                <UserCard />
                            </Grid>
                            {/* <Grid item md={4}>
                                <Reports />
                            </Grid> */}
                            <Grid item md={8}>
                                <RecentPatients />
                            </Grid>
                            {/* <Grid item md={6}>
                                <RecentActivities loading={apiReq.loading} data={dashboardData?.case_status} />
                            </Grid> */}
                            <Grid item md={8}>
                                <BasicCards />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                {/* {sidebarOpen ?
                    <Grid item md={3}>
                        <ROMACApps closeSidebar={closeSidebar} />
                    </Grid>
                    :
                    <Button sx={{ position: 'fixed', right: 0, top: 70,transform: 'rotate(-90deg) translate(0, -100%)',
                    transformOrigin: 'top right'  }} variant="contained" onClick={() => setSidebarOpen(true)}>
                     Romac Apps
                    </Button>
                } */}
            </Grid>
        </AppLayout>
    )
}